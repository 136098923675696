




















































































































import { Vue, Component } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";

@Component({
  metaInfo: {
    title: "Course Details"
  },
  components: {
    Breadcrumbs,
    Confirm
  }
})
export default class CourseCatalogDetail extends Vue {
  breadcrumbs = [
    {
      text: "Home",
      exact: true,
      to: { name: "home-en" }
    },
    {
      text: "Online Courses",
      exact: true,
      to: { name: "course-catalog-en" }
    },
    {
      text: ""
    }
  ];

  dialog = false;

  panel = [0, null, null];

  submitDisabled = false;

  get userSignedIn() {
    return this.$store.getters["auth/userSignedIn"];
  }

  get user() {
    return this.$store.getters["auth/user"];
  }

  course = {
    id: "",
    code: "",
    urlCode: "",
    urlCodeEn: "",
    name: "",
    desc: {
      short: "",
      long: ""
    },
    descEn: {
      short: "",
      long: ""
    },
    learningOutcomes: [],
    modules: [],
    img: ""
  };

  courseRegistration = {
    id: "",
    courseId: "",
    dateCreated: null
  };

  async getCourse() {
    try {
      const courseQs = await firebase
        .firestore()
        .collection("courses")
        .where("urlCodeEn", "==", this.$route.params.courseUrlCode)
        .where("disabled", "==", false)
        .limit(1)
        .get();

      const course = courseQs.docs[0];

      if (course.exists) {
        this.course.id = course.id;
        this.course.code = course.data().code;
        this.course.urlCode = course.data().urlCode;
        this.course.urlCodeEn = course.data().urlCodeEn;
        this.course.name = course.data().nameEn;
        this.course.desc = course.data().desc;
        this.course.descEn = course.data().descEn;
        this.course.learningOutcomes = course.data().learningOutcomes;
        this.course.modules = course.data().modules;
        this.course.img = `/courses/${this.course.urlCode}/preview-image.jpg`;
      }

      this.breadcrumbs[this.breadcrumbs.length - 1].text = this.course.name;

      if (this.userSignedIn) {
        this.getCourseRegistration();
      }
    } catch (err) {
      this.$notify({
        type: "error",
        text: `Eğitim bilgileri alınamadı. ${err}`
      });
    }
  }

  async getCourseRegistration() {
    try {
      const regQs = await firebase
        .firestore()
        .collection("courseRegistrations")
        .where("courseId", "==", this.course.id)
        .where("uid", "==", this.user.id)
        .where("isActive", "==", true)
        .limit(1)
        .get();

      const reg = regQs.docs[0];

      if (reg && reg.exists) {
        this.courseRegistration.id = reg.id;
        this.courseRegistration.courseId = reg.data().courseId;
        this.courseRegistration.dateCreated = reg.data().dateCreated.toDate();
      }
    } catch (err) {
      this.$notify({
        type: "error",
        text: `Eğitim kaydı bilgileri alınamadı. ${err}`
      });
    }
  }

  async register() {
    // if (this.course.urlCode == "dijital-vatandaslik") {
    //   this.dialog = true;

    //   return;
    // }

    // Kullanıcı yoksa girişe yönlendir
    if (!this.userSignedIn) {
      this.$router.push({
        name: "sign-in",
        query: {
          returnUrl: this.$route.fullPath
        }
      });

      return;
    }

    this.submitDisabled = true;

    // Öğrenciyi derse kaydet
    try {
      await firebase
        .firestore()
        .collection("courseRegistrations")
        .add({
          uid: this.user.id,
          courseId: this.course.id,
          dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
          progress: 0,
          isActive: true
        });

      this.getCourseRegistration();

      this.$notify({
        type: "success",
        text: `Ders kaydınız başarıyla gerçekleştirildi.`
      });

      // Öğrenci sayısını arttır
      const courceRef = firebase.firestore().doc(`/courses/${this.course.id}`);
      courceRef.update({
        studentCount: firebase.firestore.FieldValue.increment(1)
      });
    } catch (err) {
      this.$notify({
        type: "error",
        text: `Ders kaydı gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.`
      });
      throw err;
    } finally {
      this.submitDisabled = false;
    }
  }

  async mounted() {
    await this.getCourse();
  }
}
